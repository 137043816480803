
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mykadai-ui-primary: mat.define-palette(mat.$indigo-palette);
$mykadai-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mykadai-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mykadai-ui-theme: mat.define-light-theme((
  color: (
    primary: $mykadai-ui-primary,
    accent: $mykadai-ui-accent,
    warn: $mykadai-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mykadai-ui-theme);

/* You can add global styles to this file, and also import other style files */
 @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';
//@import '~bootstrap/dist/css/bootstrap.css';

@import 'ngx-toastr/toastr';

body {
    overflow-x: hidden;
}

.content-wrapper{
    background-color: #f2f8ff !important;
}

/*::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}*/

::-webkit-scrollbar-track {
    width: 1px;
    background: lightgray;
}

.content {
    padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
    overflow: hidden;
    display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
    transform: none;
}

/*.loader {
    position: absolute;
    margin: auto;
    top: -115px;
    bottom: 0;
    left: -40px;
    right: 0;
    width: 6.250em;
    height: 6.250em;
    animation: rotate5123 2.4s linear infinite;
}

.white {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    animation: flash 2.4s linear infinite;
    opacity: 0;
}

.dot {
    position: absolute;
    margin: auto;
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
    transition: all 1s ease;
}

.dot:nth-child(2) {
    top: 0;
    bottom: 0;
    left: 0;
    background: #FF4444;
    animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(3) {
    left: 0;
    right: 0;
    top: 0;
    background: #FFBB33;
    animation: dotsX 2.4s linear infinite;
}

.dot:nth-child(4) {
    top: 0;
    bottom: 0;
    right: 0;
    background: #99CC00;
    animation: dotsY 2.4s linear infinite;
}

.dot:nth-child(5) {
    left: 0;
    right: 0;
    bottom: 0;
    background: #33B5E5;
    animation: dotsX 2.4s linear infinite;
}

@keyframes rotate5123 {
    0% {
        transform: rotate( 0 );
    }

    10% {
        width: 6.250em;
        height: 6.250em;
    }

    66% {
        width: 2.4em;
        height: 2.4em;
    }

    100% {
        transform: rotate(360deg);
        width: 6.250em;
        height: 6.250em;
    }
}

@keyframes dotsY {
    66% {
        opacity: .1;
        width: 2.4em;
    }

    77% {
        opacity: 1;
        width: 0;
    }
}

@keyframes dotsX {
    66% {
        opacity: .1;
        height: 2.4em;
    }

    77% {
        opacity: 1;
        height: 0;
    }
}

@keyframes flash {
    33% {
        opacity: 0;
        border-radius: 0%;
    }

    55% {
        opacity: .6;
        border-radius: 100%;
    }

    66% {
        opacity: 0;
    }
}*/

.loader {
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    width: 110px;
    height: 110px;
    border-radius: 50%;
    perspective: 800px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 5px solid #eef3f2;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 5px solid #ef6210;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 5px solid hsl(129, 86%, 48%);
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

.order-card {
    border-radius: 12px !important;
    margin: 10px;
    background-color: #fff;
    border: 1px solid rgba(212, 212, 212, 0.4);
}

.table-green th {
    background-color: #d7efff !important;
    color: #1d5bc7 !important;
    text-align: center;
    padding: 12px !important;
}

.badge-gradient-green {
    box-shadow: #8af38a !important;
    color: #0c622e !important;
    background-color: #dcf1e4 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.badge-gradient-blue {
    box-shadow: #8ae3f3 !important;
    color: rgb(8, 70, 130) !important;
    background-color: rgb(115, 188, 253) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.badge-gradient-yellow {
    box-shadow: #ecf38a !important;
    color: #fd7e14 !important;
    background-color: rgb(245, 237, 166) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.badge-gradient-red {
    box-shadow: #fae4e8 !important;
    color: #b03d50 !important;
    background-color: #fae4e8 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.badge-gradient-secondary {
    box-shadow: #f1f2f3 !important;
    color: #404247 !important;
    background-color: #f1f2f3 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.badge-gradient-info {
    box-shadow: #e5f4f8 !important;
    color: #3b7e94 !important;
    background-color: #e5f4f8 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.table-style {
    height: 400px;
    overflow-y: scroll;
}
 label {
     font-weight: 400 !important;
 }